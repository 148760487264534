<template>
  <b-overlay
    :show="overlayChartOnboard"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card
      no-body
      :aria-hidden="overlayChartOnboard ? true : null"
    >
      <b-card-header>
        <h4 class="mb-0">
          Member Onboarding
        </h4>
      </b-card-header>

      <!-- apex chart -->
      <vue-apex-charts
        type="radialBar"
        height="245"
        class="my-2"
        :options="memberOnboarding"
        :series="memberOnboarding.data.series"
      />
      <b-row class="text-center mx-0">
        <b-col
          cols="6"
          class="border-top border-right d-flex align-items-between flex-column py-1"
        >
          <b-card-text class="text-muted mb-0">
            Completed
          </b-card-text>
          <h3 class="font-weight-bolder mb-0">
            {{ memberOnboarding.data.completed }}
          </h3>
        </b-col>

        <b-col
          cols="6"
          class="border-top d-flex align-items-between flex-column py-1"
        >
          <b-card-text
            v-if="active_role === 'corporate'"
            class="text-muted mb-0"
          >
            Max. Member
          </b-card-text>
          <b-card-text
            v-else
            class="text-muted mb-0"
          >
            In Progress
          </b-card-text>
          <h3 class="font-weight-bolder mb-0">
            {{ memberOnboarding.data.progress }}
          </h3>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol,
  BOverlay, BCard, BCardText, BCardHeader,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $chartOnboardingColor = '#51e5a8'

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BCard,
    BCardText,
    BCardHeader,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      memberOnboarding: {
        data: {
          completed: 0,
          progress: 0,
          series: [],
        },
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$chartOnboardingColor],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '2.86rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
      overlayChartOnboard: true,
      active_role: null,
    }
  },
  created() {
    this.active_role = localStorage.getItem('role')
    this.chartMemberOnboarding()
  },
  methods: {
    async chartMemberOnboarding() {
      const cid = localStorage.getItem('client_id')
      const memberOnboard = this.memberOnboarding.data

      const client = await this.$axios.get(`all/client/${cid}`)
      let member = null
      const clientData = client.data.data

      if (this.active_role === 'corporate') {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }

        const corporate = await this.$axios.get(`${cid}/member/corporate/detail`, config)
        member = await this.$axios.get(`${cid}/member/corporate/user`, config)
        memberOnboard.completed = corporate.data.data.total_member
      } else {
        member = await this.$axios.get(`${cid}/noauth/member`)
        memberOnboard.completed = clientData.client.client_member_target
      }

      const memberData = member.data.data

      memberOnboard.progress = memberData.length

      const percentage = Math.floor((memberOnboard.progress / memberOnboard.completed) * 100)
      memberOnboard.series.push(percentage)

      this.overlayChartOnboard = false
    },
  },
}
</script>
