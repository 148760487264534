<template>
  <b-overlay
    :show="overlayChartSES"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card
      no-body
      :aria-hidden="overlayChartSES ? true : null"
    >
      <b-card-header>
        <b-card-title>Socioeconomic Status (SES)</b-card-title>
      </b-card-header>

      <b-card-body>
        <!-- apex chart -->
        <vue-apex-charts
          ref="chartSes"
          type="pie"
          height="325"
          class="mt-2 mb-1"
          :options="sesChart.chartOptions"
          :series="sesChart.series"
        />

        <!-- list group -->
        <div class="pt-25">
          <div
            v-for="(ses, index) in sesChart.data"
            :key="index"
            class="d-flex justify-content-between"
            :class="index === Object.keys(sesChart.data).length - 1 ? '':'mb-1'"
          >
            <div class="series-info">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="mr-50"
                :style="{ color: ses.iconColor }"
              />
              <span
                class="font-weight-bolder"
                style="margin-left: 1px; margin-top: 2px"
              >
                {{ ses.text }}
              </span>
            </div>
            <span>{{ ses.result }}%</span>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BOverlay,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      sesChart: {
        data: [
          {
            iconColor: '#F9C80E',
            text: 'Mikro (< 300 Jt)',
            result: 40,
          },
          {
            iconColor: '#03A9F4',
            text: 'Kecil (300 Jt s/d 2.5 M)',
            result: 20,
          },
          {
            iconColor: '#4CAF50',
            text: 'Menengah (2.5 M s/d 50 M)',
            result: 15,
          },
          {
            iconColor: '#D7263D',
            text: 'Besar (> 50 M)',
            result: 25,
          },
          {
            iconColor: '#546E7A',
            text: 'Unknown',
            result: 5,
          },
        ],
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: [],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          stroke: {
            width: 4,
          },
          colors: [],
          tooltip: {
            enabled: true,
            custom({ series, seriesIndex, w }) {
              return `<span style="background: ${w.config.colors[seriesIndex]}; padding: 10px; font-size: 12px">
                  ${w.config.labels[seriesIndex]}: <b>${series[seriesIndex]}%<b/>
                </span>`
            },
          },
        },
      },
      overlayChartSES: true,
    }
  },
  computed: {
    data() {
      return this.$store.state.dashboard.ses
    },
  },
  watch: {
    data(newVal) {
      if (newVal) {
        this.chartSes()
      }
    },
  },
  methods: {
    async chartSes() {
      const chartSes = this.sesChart
      const chartOpt = chartSes.chartOptions

      chartOpt.label = this.data.data.map(item => item.text)

      const chartSeries = this.data.chart
      const chartData = []

      this.data.data.forEach(item => {
        // Data add
        chartData.push({
          iconColor: item.color,
          text: item.text,
          result: item.value,
        })
      })

      chartSes.data = chartData
      chartSes.series = chartSeries

      this.$refs.chartSes.updateSeries([{
        data: chartSeries,
      }], false, true)

      this.$refs.chartSes.updateOptions({
        colors: this.data.data.map(item => item.color),
        labels: this.data.data.map(item => item.text),
      })

      this.overlayChartSES = false
    },
  },
}
</script>
