<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-50">
        <h4 class="mb-0">
          Member Report
        </h4>
      </b-card-title>
    </b-card-header>

    <vue-apex-charts
      type="bar"
      height="350"
      class="mx-2"
      :options="memberReport.chartOptions"
      :series="memberReport.series"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      memberReport: {
        series: [
          {
            name: 'Dropshipper',
            data: [0, 1, 5, 12, 10, 19],
          },
          {
            name: 'Produsen',
            data: [0, 2, 5, 21, 15, 6],
          },
          {
            name: 'Consumer',
            data: [0, 0, 10, 15, 9, 5],
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: ['#008FFB', '#4CAF50', '#F86624'],
          plotOptions: {
            bar: {
              columnWidth: '15%',
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left',
          },
          stroke: {
            show: true,
            colors: ['transparent'],
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: ['08/2021', '09/2021', '10/2021', '11/2021', '12/2021', '01/2022'],
          },
          yaxis: {
            // opposite: isRtl,
          },
          fill: {
            opacity: 1,
          },
        },
      },
    }
  },
  created() {

  },
  methods: {

  },
}
</script>
