<template>
  <b-overlay
    :show="overlayChartOccupation"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card
      no-body
      :aria-hidden="overlayChartOccupation ? true : null"
    >
      <b-card-header>
        <b-card-title class="mb-50">
          <h4 class="mb-0">
            Occupation
          </h4>
        </b-card-title>
      </b-card-header>

      <vue-apex-charts
        ref="chartOccupation"
        type="bar"
        height="300"
        class="mx-2"
        :options="memberJobs.chartOptions"
        :series="memberJobs.series"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BOverlay,
    BCard,
    BCardHeader,
    BCardTitle,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      memberJobs: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: ['#008FFB'],
          plotOptions: {
            bar: {
              columnWidth: '15%',
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left',
          },
          stroke: {
            show: true,
            colors: ['transparent'],
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: [],
          },
          fill: {
            opacity: 1,
          },
        },
      },
      overlayChartOccupation: true,
    }
  },
  computed: {
    data() {
      return this.$store.state.dashboard.occupation
    },
  },
  watch: {
    data(newVal) {
      if (newVal) {
        this.chartOccupation()
      }
    },
  },
  methods: {
    async chartOccupation() {
      this.$refs.chartOccupation.updateSeries([{
        data: this.data.data.map(item => ({
          x: item.name,
          y: item.value,
        })),
      }], false, true)

      this.overlayChartOccupation = false
    },
  },
}
</script>
