<template>
  <b-overlay
    :show="overlayChartEducation"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card
      no-body
      :aria-hidden="overlayChartEducation ? true : null"
    >
      <b-card-header>
        <b-card-title class="mb-50">
          <h4 class="mb-0">
            Education
          </h4>
        </b-card-title>
      </b-card-header>

      <vue-apex-charts
        ref="chartEducation"
        type="bar"
        height="300"
        class="mx-2"
        :options="memberEducation.chartOptions"
        :series="memberEducation.series"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BOverlay,
    BCard,
    BCardHeader,
    BCardTitle,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      memberEducation: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: ['#4CAF50'],
          plotOptions: {
            bar: {
              columnWidth: '15%',
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left',
          },
          stroke: {
            show: true,
            colors: ['transparent'],
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: ['SD', 'SMP', 'SMA/SMK', 'Diploma', 'Sarjana', 'Pasca Sarjana', 'Unknown'],
          },
          fill: {
            opacity: 1,
          },
        },
      },
      overlayChartEducation: true,
    }
  },
  computed: {
    data() {
      return this.$store.state.dashboard.education
    },
  },
  watch: {
    data(newVal) {
      if (newVal) {
        this.chartEducation()
      }
    },
  },
  methods: {
    async chartEducation() {
      const chartEducation = this.memberEducation
      const chartOpt = chartEducation.chartOptions

      chartOpt.label = this.data.data.map(item => item.name)

      // let chartSeries = [0, 0, 147, 93, 44, 0, 0]
      const chartSeries = this.data.chart

      chartEducation.series[0].data = chartSeries
      this.$refs.chartEducation.updateSeries([{
        data: chartSeries,
      }], false, true)

      this.overlayChartEducation = false
    },
  },
}
</script>
