<template>
  <b-overlay
    :show="overlayChartUsaha"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card
      no-body
      :aria-hidden="overlayChartUsaha ? true : null"
    >
      <b-card-header>
        <b-card-title>
          <h4 class="mb-0">
            Business Category
          </h4>
        </b-card-title>
      </b-card-header>

      <!-- chart info -->
      <vue-apex-charts
        ref="chartUsaha"
        type="donut"
        height="300"
        class="my-1"
        :options="usahaData.chartOptions"
        :series="usahaData.series"
      />
      <div
        v-for="(usaha, index) in usahaData.data"
        :key="index"
        class="d-flex justify-content-between mx-2 mb-2"
        :class="index === usahaData.data.length - 1 ? 'mb-0':'mb-1'"
      >
        <div class="series-info d-flex align-items-center">
          <feather-icon
            icon="UserIcon"
            size="16"
            :style="{ color: usaha.iconColor }"
          />
          <span class="font-weight-bolder ml-75 mr-25">{{ usaha.name }}</span>
          <!-- <span>- {{ usaha.usage }}%</span> -->
        </div>
        <div>
          <span>{{ usaha.usage }}%</span>
          <!-- <feather-icon
            :icon="usaha.upDown > 0 ? 'ArrowUpIcon':'ArrowDownIcon'"
            :class="usaha.upDown > 0 ? 'text-success':'text-danger'"
            class="mb-25 ml-25"
          /> -->
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BOverlay,
    BCard,
    BCardHeader,
    BCardTitle,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      usahaData: {
        data: [],
        series: [20, 30, 15, 35, 10],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: [],
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            enabled: true,
            custom({ series, seriesIndex, w }) {
              return `<span style="background: ${w.config.colors[seriesIndex]}; padding: 10px; font-size: 12px">
                  ${w.config.labels[seriesIndex]}: <b>${series[seriesIndex]}%<b/>
                </span>`
            },
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
          colors: [],
        },
      },
      overlayChartUsaha: true,
    }
  },
  computed: {
    data() {
      return this.$store.state.dashboard.business_cat
    },
  },
  watch: {
    data(newVal) {
      if (newVal) {
        this.chartMemberUsaha()
      }
    },
  },
  methods: {
    chartMemberUsaha() {
      const chartUsaha = this.usahaData
      const chartOpt = chartUsaha.chartOptions

      chartOpt.labels = this.data.data.map(item => item.name)

      const chartColor = [
        '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0',
        '#546E7A', '#4CAF50', '#662E9B', '#C5D86D', '#5A2A27',
      ]
      chartOpt.colors = chartColor

      let i = 0
      const chartData = []
      const chartSeries = this.data.chart
      this.data.data.forEach(item => {
        const itemChart = {
          iconColor: chartColor[i],
          name: item.name,
          usage: item.value,
          upDown: 0,
        }

        chartData.push(itemChart)

        i += 1
      })

      chartData.sort((a, b) => b.usage - a.usage)
      chartUsaha.data = chartData
      chartUsaha.series = chartSeries

      this.$refs.chartUsaha.updateSeries([{
        data: chartSeries,
      }], false, true)

      this.$refs.chartUsaha.updateOptions({
        colors: chartColor,
        labels: this.data.data.map(item => item.name),
      })

      this.overlayChartUsaha = false
    },
  },
}
</script>
