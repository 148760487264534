<template>
  <b-overlay
    :show="overlayChartMen"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card
      body-class="pb-50"
      :aria-hidden="overlayChartMen ? true : null"
    >
      <h6>Man</h6>
      <h2 class="font-weight-bolder mb-1">
        {{ memberMenTotal }}
      </h2>
      <!-- chart -->
      <vue-apex-charts
        ref="chartMen"
        height="70"
        :options="memberMen.chartOptions"
        :series="memberMen.series"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'

const $charWomenColor = '#EBEBEB'

export default {
  components: {
    BOverlay,
    BCard,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      memberMen: {
        series: [{
          data: [],
        }],
        chartOptions: {
          chart: {
            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $charWomenColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -25,
              bottom: -5,
            },
          },
          stroke: {
            width: 2,
          },
          colors: [$themeColors.info],
          markers: {
            size: 2,
            colors: $themeColors.info,
            strokeColors: $themeColors.info,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: '#ffffff',
                strokeColor: $themeColors.info,
                size: 5,
              },
            ],
            shape: 'circle',
            radius: 1,
            hover: {
              size: 2,
            },
          },
          xaxis: {
            labels: {
              show: false,
              style: {
                fontSize: '0px',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
      memberMenTotal: 0,
      overlayChartMen: true,
    }
  },
  computed: {
    data() {
      return this.$store.state.dashboard.man_member
    },
  },
  watch: {
    data(newVal) {
      if (newVal) {
        this.overlayChartMen = true
        this.chartMemberMen()
      }
    },
  },
  methods: {
    chartMemberMen() {
      const chartData = this.data.chart

      this.memberMen.series[0].data = chartData
      this.memberMenTotal = this.data.total

      this.$refs.chartMen.updateSeries([{
        data: chartData,
      }], false, true)

      setTimeout(() => {
        this.overlayChartMen = false
      }, 1000)
    },
  },
}
</script>
