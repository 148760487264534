<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Digital Literacy</b-card-title>
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="polarArea"
        :options="digitalLiteracy.chartOptions"
        :series="digitalLiteracy.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      digitalLiteracy: {
        series: [50, 65, 26],
        chartOptions: {
          chart: {
            type: 'polarArea',
          },
          labels: ['Beonco', 'Manna', 'Unknown'],
          colors: ['#EA3546', '#03A9F4', '#546E7A'],
          stroke: {
            colors: ['#fff'],
          },
          fill: {
            opacity: 0.8,
          },
          yaxis: {
            show: false,
          },
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
        },
      },
    }
  },
  created() {

  },
  methods: {

  },
}
</script>
