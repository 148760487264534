<template>
  <b-overlay
    :show="overlayMemberText"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card
      class="earnings-card"
      :aria-hidden="overlayMemberText ? true : null"
    >
      <b-row>
        <b-col cols="6">
          <b-card-title class="mb-2">
            <h6>Tech Savvy</h6>
          </b-card-title>
          <h5 class="mb-1">
            {{ totalMemberTextSavvy }} person
          </h5>
          <b-card-text class="text-muted font-small-2">
            <span class="font-weight-bolder">{{ totalMemberTextSavvyPercen }}%</span>
            <span> person know about social media technology.</span>
          </b-card-text>
        </b-col>
        <b-col cols="6">
          <!-- chart -->
          <vue-apex-charts
            ref="chartSavvy"
            height="130"
            :options="memberTextSavvy.chartOptions"
            :series="memberTextSavvy.series"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>

</template>

<script>
import {
  BRow, BCol,
  BOverlay, BCard, BCardText, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BCard,
    BCardText,
    BCardTitle,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      memberTextSavvy: {
        series: [],
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            enabled: true,
            custom({ series, seriesIndex, w }) {
              return `<span style="background: ${w.config.colors[seriesIndex]}; padding: 10px; font-size: 12px">
                  ${w.config.labels[seriesIndex]}: <b>${series[seriesIndex]}%<b/>
                </span>`
            },
          },
          legend: { show: false },
          labels: ['Yes', 'No', 'Unknown'],
          stroke: { width: 0 },
          colors: [$themeColors.primary, $themeColors.danger, '#c0c0c0'],
          expandOnClick: false,
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                },
              },
            },
          },
        },
      },
      totalMemberTextSavvy: 0,
      totalMemberTextSavvyPercen: 0,
      overlayMemberText: true,
    }
  },
  computed: {
    data() {
      return this.$store.state.dashboard.tech_savvy
    },
  },
  watch: {
    data(newVal) {
      if (newVal) {
        this.chartMemberSavvy()
      }
    },
  },
  methods: {
    async chartMemberSavvy() {
      const chartSavvy = this.memberTextSavvy
      const chartSeriesNumber = this.data.chart

      const [firstChartSeries] = chartSeriesNumber
      this.totalMemberTextSavvy = firstChartSeries

      // Convert to percentage
      const chartSeries = chartSeriesNumber.map(item => Math.round((item / this.data.total) * 100))
      chartSavvy.series = chartSeries

      const [firstChart] = chartSeries
      this.totalMemberTextSavvyPercen = firstChart

      this.overlayMemberText = false
    },
  },
}
</script>
